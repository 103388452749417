import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "../../components/Button"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO";

const CaseStudies = () => {
  return (
    <Layout>
      <SEO title="Case Studies" />
      <div>
        <div className="flex flex-col justify-between  bg-black ">
          <div className="flex justify-center px-4 lg:px-16 pt-32  lg:pt-48 bg-black text-white">
            <h1 className="text-6xl font-bold">Case Studies</h1>
          </div>

          <div className="flex flex-col-reverse lg:flex-row bg-black text-white py-12 lg:py-40 ">
            <div className="flex flex-col text-white px-4 lg:px-16 pt-12  lg:w-1/2 lg:pb-0 pb-12">
              <h1 className="text-3xl lg:text-6xl pb-8  font-extrabold">
                MALTINA DANCE ALL
              </h1>

              <p className="lg:text-lg font-light pb-8">
                Maltina Dance All Season VI had succeeded to be one of the best
                family dance reality show in Nigeria.
              </p>

              <p className="lg:text-lg font-light pb-8">
                Season 6 in particular had a huge family and viewer engagement,
                with an estimated 22 million viewers and 4,000+ participants
                nationwide. Brand awareness increased significantly, with the
                Facebook page growing from 40,000 to 100,000+ followers at the
                end of the campaign.
              </p>

              <Button
                action="Learn more"
                link="/projects/maltina"
                inverse={true}
              />
            </div>

            <div className="flex lg:w-1/2 px-8">
              <StaticImage
                placeholder="blurred"
                src="../images/image.png"
                alt="Maltina Dance All - Media Seal WPI"
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row  bg-white text-black py-12 lg:py-40 ">
            <div className="flex lg:w-1/2 lg:pt-0 pt-12 px-8">
              <StaticImage
                placeholder="blurred"
                src="../../images/case-studies/Samsung-Logo.jpeg"
                alt="Samsung - Media Seal WPI"
              />
            </div>

            <div className="flex flex-col text-black  px-4 lg:px-16 pt-12  lg:pb-0 pb-12 lg:w-1/2">
              <h1 className="text-3xl lg:text-6xl pb-8  font-extrabold">
                SAMSUNG FAN PAGE
              </h1>

              <p className="lg:text-lg font-light pb-8">
                We teamed up with Beat 99.9fm which had the highest social media
                followership and engagement amongst radio stations that year.
              </p>

              <p className="lg:text-lg font-light pb-8">
                We also teamed up with a trendy Nightclub to host Samsung fans
                for a night of free drinks and entertainment. The Samsung mobile
                fan page grew from 3,000+ to over 66,000 followers during the
                course of the campaign.
              </p>

              <Button action="Learn more" link="/projects/samsung" />
            </div>
          </div>

          <div className="flex flex-col-reverse lg:flex-row  bg-black text-white py-12 lg:py-40">
            <div className="flex flex-col text-white px-4 lg:px-16 pt-12  lg:w-1/2 lg:pb-0 pb-12">
              <h1 className="text-3xl lg:text-6xl pb-8  font-extrabold">
                BET KING AWARENESS CAMPAIGN
              </h1>

              <p className="lg:text-lg font-light pb-8">
                User traffic grew by 67.3% within the first month of the
                campaign, delivering a 45.3% conversion rate increase.
              </p>

              <p className="lg:text-lg font-light pb-8">
                The customer base skyrocketed, recording a remarkable growth
                from 200 active customers before brand launch in 2017 to
                300,000+ active customers by the end of 2018.
              </p>

              <Button
                action="Learn more"
                link="/projects/bet-king"
                inverse={true}
              />
            </div>

            <div className="flex lg:w-1/2  px-8 lg:pt-0 pt-12">
              <StaticImage
                placeholder="blurred"
                src="../images/image (2).png"
                alt="Maltina Dance All - Media Seal WPI"
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row  bg-white text-black py-12 lg:py-40">
            <div className="flex lg:w-1/2 items-center px-8 lg:pt-0 pt-12">
              <StaticImage
                placeholder="blurred"
                src="../../images/case-studies/tlc.png"
                alt="Samsung - Media Seal WPI"
              />
            </div>

            <div className="flex flex-col text-black  px-4 lg:px-16 pt-12   lg:w-1/2 lg:pb-0 pb-12">
              <h1 className="text-3xl lg:text-6xl pb-8  font-extrabold">
                LA-CASERA
              </h1>

              <p className="lg:text-lg font-light pb-8">
                We teamed up with Beat 99.9fm which had the highest social media
                followership and engagement amongst radio stations that year.
              </p>

              <p className="lg:text-lg font-light pb-8">
                We also teamed up with a trendy Nightclub to host Samsung fans
                for a night of free drinks and entertainment. The Samsung mobile
                fan page grew from 3,000+ to over 66,000 followers during the
                course of the campaign.
              </p>

              <Button action="Learn more" link="/projects/la-casera" />
            </div>
          </div>

          <div className="flex flex-col-reverse lg:flex-row py-12 lg:py-40 ">
            <div className="flex flex-col text-white bg-black px-4 lg:px-16 pt-12  lg:w-1/2 lg:pb-0 pb-12">
              <h1 className="text-3xl lg:text-6xl pb-8  font-extrabold">
                STAR
              </h1>

              <p className="lg:text-lg font-light pb-8">
                The Star Trek City Storm biggest idea was to bring top music
                ambassadors closer to the Grass root target market, with
                specific most-listened-to music artistes to platforms in 10
                focus locations.
              </p>

              <p className="lg:text-lg font-light pb-8">
                Mobile and social campaign pages were developed to support this
                project. The brand loved the deeper level of conversation and
                the level of energy and affinity between the consumer and star.
              </p>

              <Button
                action="Learn more"
                link="/projects/star"
                inverse={true}
              />
            </div>

            <div className="flex lg:w-1/2 items-center justify-center px-8 lg:pt-0 pt-12">
              <StaticImage
                placeholder="blurred"
                src="../../images/webimages-2/Star.png"
                alt="Maltina Dance All - Media Seal WPI"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudies
